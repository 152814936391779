var render, staticRenderFns
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./footer.vue?vue&type=custom&index=0&blockType=v-col&sm=12&col=12&class=pa-0%20pl-3&style=align-items%3A%20flex-end%3Bdisplay%3A%20flex%3B"
if (typeof block0 === 'function') block0(component)

export default component.exports